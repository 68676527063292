<template>
  <base-selector
    :items="items"
    label="Task status"
    :initial-value="filters.status"
    @select="selectStatus"
  />
</template>

<script>
  import BaseSelector from '@/components/base/selectors/BaseSelector';
  import { mapGetters } from 'vuex';
  import { queryFiltersMixin } from '@/mixins/QueryFiltersMixin';

  export default {
    name: 'TasksPageFilterStatus',
    components: { BaseSelector },
    mixins: [queryFiltersMixin],
    data () {
      return {
        items: [
          { label: 'Credential status', value: '' },
          { label: 'Active', value: 'active' }, // active, inactive, invalid, deleted
          { label: 'Inactive', value: 'inactive' },
          { label: 'Invalid', value: 'invalid' },
          { label: 'Deleted', value: 'deleted' },
        ],
      };
    },
    computed: {
      ...mapGetters({
        filters: 'credentials/filters',
      }),
    },
    methods: {
      async selectStatus (newStatus) {
        await this.$store.dispatch('credentials/SET_FILTERS', { status: newStatus });
        await this.setQueryByFilters(this.filters);

        await this.$store.dispatch('credentials/LOAD_CREDENTIALS_LIST');
      },
    },
  };
</script>

<style scoped>

</style>
