<template>
  <v-select
    v-model="select"
    :items="items"
    item-text="label"
    item-value="value"
    label="Select"
    persistent-hint
    return-object
    single-line
  />
</template>

<script>
  import { selectorsMixin } from '@/mixins/selectors/selectorsMixin';

  export default {
    name: 'BaseSelector',
    mixins: [selectorsMixin],
    props: {
      initialValue: {
        type: Object,
        default: () => ({
          label: 'All',
          value: '',
        }),
      },
    },
  };
</script>

<style scoped>

</style>
